import React from "react"
import PropTypes from "prop-types"
import GraphImg from "graphcms-image"

const GridGallery = ({data}) => (
	<div className={`grid-gallery-wrap container ${data.imageNumb}`}>
		{data.images.map(galleryItem => (
			<GraphImg
				key={galleryItem.id}
				image={galleryItem}
				fit="scale"
				backgroundColor={true}
				withWebp={true}
				alt={galleryItem.fileName}/>
		))}
	</div>
)

GridGallery.propTypes = {
	data: PropTypes.object.isRequired
}

export default GridGallery
