import React from "react"

const BenefitsSection = (cardData) => (
	<div className="benefits-wrap">
		<div className="container">
			<div className="benefits-text-wrap">
				<h2>Benefits</h2>
				<p>Basic principle of our company is respect. We are tolerant and we appreciate individuality in our team.
					Every team is made of individuals working towards a common goal. We have very low staff turnover because of the
					friendly atmosphere in the office. We really mean it. Most of our employees are with us for a long period of time.
					Developing talent is a key value for our business growth.</p>
			</div>
			<div className="benefits-cards-wrap">
				{cardData.cardData.map(benefit => (
					<div key={benefit.id} className="benefits-cards__item">
						<img src={benefit.icon.url} alt={benefit.icon.fileName} />
						<h4>{benefit.heading}</h4>
						<p>{benefit.copy}</p>
					</div>
				))}
			</div>
		</div>
	</div>
)
export default BenefitsSection
