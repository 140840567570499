import React from "react"
import { StaticQuery, graphql } from "gatsby"

const OpeningsSection = () => (
	<StaticQuery
		query={graphql`
			query {
				dnhCms {
					openingses(where: {status: PUBLISHED}) {
						id
						iconUnicode
						role
						location
						type
						posted
						link
					}
				}
			}
		`}
		render={data => (
			<div className="openings-wrap container">
				<div className="openings-text-wrap">
					<h2>Current Openings</h2>
					<p>Check out our current openings. Click the Apply button to see more information about the position and apply for it,
						if it is a match for you. In case you do not find anything which is the perfect match for you but you think that you
						have a lot to offer, feel free to contact us. We are always willingly looking for new talents.</p>
				</div>
				<table className="openings-table">
					<thead>
						<tr>
							<th>Role</th>
							<th>location</th>
							<th>type</th>
							<th>Posted</th>
						</tr>
					</thead>
					<tbody>
						{data.dnhCms.openingses.map(opening => (
							<tr key={opening.id}>
								<td>
									<span className="openings-table__text">
										<i className={`fab ${opening.iconUnicode}`}></i>
										{opening.role}
									</span>
								</td>
								<td>
									<span className="openings-table__text">{opening.location}</span>
								</td>
								<td>
									<span className="openings-table__text">{opening.type.split('_').join(' ')}</span>
								</td>
								<td>
									<span className="openings-table__text">{opening.posted.substring(0, opening.posted.indexOf('T'))}</span>
								</td>
								<td>
									<a  href={opening.link}
										className="openings-table__link"
										target="_blank"
										rel="noopener noreferrer">
										APPLY
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		)}
	/>
)
export default OpeningsSection
