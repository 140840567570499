import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import YouTube from 'react-youtube';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Logo from "../../components/logo"
import TextSection from "../../components/textSection"
import GridGallery from "../../components/gridGallery"
import OpeningsSection from "../../components/openingsSection"
import BenefitsSection from "../../components/benefitsSection"

const Careers = (props) => {
	const data = useStaticQuery(graphql`
		query {
			dnhCms {
				subPages(where: {pageTitle: "Careers", status: PUBLISHED}) {
					id
					bannerHeading
					pageTitle
					pageDescription
					heading
					copy
					pageBanner {
						url
					}
					gridGallery {
						id
						images(first: 6) {
							id
							handle
							width
							height
							fileName
						}
						imageNumb
					}
					infoCards(where: {status: PUBLISHED}) {
						id
						heading
						copy
						icon {
							url
							fileName
						}
					}
					youtubeVideo {
						videoId
					}
				}
			}
		}
	`)
	return(
		<>
			<Helmet
				bodyAttributes={{
					class: data.dnhCms.subPages[0].pageTitle.replace(/\s+/g, '-').toLowerCase()
				}}
			/>
			<Layout location={props.location.pathname}
					bannerHeading={data.dnhCms.subPages[0].bannerHeading}
					bannerUrl={data.dnhCms.subPages[0].pageBanner.url}>
				<SEO title={data.dnhCms.subPages[0].pageTitle}
					description={data.dnhCms.subPages[0].pageDescription}/>
				<Logo type="Black" />
				<TextSection heading={data.dnhCms.subPages[0].heading}
							copy={data.dnhCms.subPages[0].copy}
							textSize="big"
							textColor="blue"
							bgColor="whiteBg"/>
				{data.dnhCms.subPages[0].gridGallery ?
					<GridGallery data={data.dnhCms.subPages[0].gridGallery} imgNumb={data.dnhCms.subPages[0].gridGallery.imageNumb} />
					: null
				}
				<div className="careers-video-wrap">
					<div className="careers-video-holder">
						<YouTube
							videoId={data.dnhCms.subPages[0].youtubeVideo.videoId}
							className="careers-video"
						/>
					</div>
					<div id="openings"></div>
				</div>
				<OpeningsSection />
				<BenefitsSection cardData={data.dnhCms.subPages[0].infoCards} />
				<div id="openings-contact"></div>
			</Layout>
		</>
	)
}

Careers.propTypes = {
	location: PropTypes.object
}

export default Careers
